<template>
   <v-container 
      style="max-width: 2000px;padding-top:66px;background-color:white;height:100vh;width:100vw;overflow-y:auto;overflow-x:hidden;"
      class="bgImage"
      :style="{
         'background-image': backgroundImg?backgroundImg:'none',
         'background-color': backgroundColor?backgroundColor:'none'
         }"
      >
      <v-row v-if='!isAdmin'>
         <v-col cols='12' xs='11' md='6'>
            <v-card>
               <v-card-text>
                  <div class='text-center' style='color:#898989;font-size:18px;'>
                     <v-icon color='red' class='mr-1'>mdi-lock</v-icon>
                     Bạn chưa được cấp quyền để quản lý nhân viên
                  </div>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>

      <v-row class="pa-1 no-blur" v-if='isAdmin'>
         <v-col cols="12" align="center" class="px-1">
            <v-card flat style="max-width:1080px;border-radius:8px;min-height:320px;">
               <v-card-title
                  class="py-2 px-2"
                  dark
                  style="color:teal;border-bottom: 1px solid teal;"
               >
                  <v-btn
                     color="primary"
                     dark
                     class="text-none mr-2 mb-2"
                     @click="showCreateUser()"
                     >
                     <v-icon small class="mr-2">mdi-plus</v-icon>
                        Thêm nhân viên
                  </v-btn>

                  <v-btn :disabled="selected_users.length<=0"
                     color="red"
                     dark
                     class="text-none mr-2 mb-2"
                     @click="callDeleteUsersAPI"
                     >
                     <v-icon small class="mr-2">mdi-delete</v-icon>
                        Xóa
                  </v-btn>   
                  <v-spacer />
                  <v-text-field
                     class="mb-2"
                     v-model="searchString"
                     append-icon="mdi-magnify"
                     label="Tìm kiếm"
                     clearable
                     outlined
                     single-line
                     hide-details
                     style="max-width:320px;"
                     ></v-text-field>
               </v-card-title>

               <v-card-text class="pa-0 pt-2" style="min-height:300px;">
                  <v-data-table
                     flat
                     v-model="selected_users"
                     :headers="headers"
                     :items="users"
                     :loading="loading"
                     loading-text="Đang tải... Vui lòng đợi"
                     no-data-text="Chưa có nhân viên nào"
                     item-key="_id"
                     :mobile-breakpoint="0"
                     :items-per-page="pageSize"
                     show-select
                     class="elevation-0 usertable"
                     :search="searchString"
                     :page.sync="usersPage"
                     @page-count="pageCount = $event"
                     hide-default-footer
                     @click:row="onUserRowClick"
                     >

                     <template
                        v-slot:item.roleStr="{ item }"
                     >
                        <div v-if='item.roleStr && item.roleStr.length>0'>
                           {{item.roleStr}}
                           <v-btn text class='ml-4 px-1 text-none' color='red' @click.stop='removeRole(item)'>
                              <b>Xóa quyền</b></v-btn>
                        </div>
                        <div v-else>
                           <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-btn color="primary" class='text-none'
                                    dark v-on="on" v-bind="attrs"
                                 >
                                    Cấp quyền
                                    <v-icon class='ml-1'>mdi-chevron-down</v-icon>
                                 </v-btn>
                              </template>
                              <v-list class='ma-0 pa-0'>
                                 <v-list-item link @click="assignRole(item._id, 'admin')">
                                    <v-list-item-title>Quyền admin</v-list-item-title>
                                 </v-list-item>
                                 <v-divider/>
                                 <v-list-item link @click="assignRole(item._id, 'operator')">
                                    <v-list-item-title>Quyền vận hành</v-list-item-title>
                                 </v-list-item>
                              </v-list>
                           </v-menu>
                        </div>
                     </template>
                  </v-data-table>
                  <div v-if="pageCount>1"
                     class="text-center pt-2">
                  <v-pagination
                     v-model="usersPage"
                     :length="pageCount"
                     ></v-pagination>
                  </div>
               </v-card-text>
            </v-card>
         </v-col>

         <v-dialog v-model='dialogUser' persistent
            width="800px">
            <user-editor :user='activeUser' 
               @close="dialogUser=false"
               @saveDone="dialogUser=false;fetchUsers()" />
         </v-dialog>
      </v-row>
   </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
   computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
        isAdmin: 'adminRole', 
      }),
   },
   data () {
      return {
         backgroundImg: null,
         backgroundColor: null,
         blurBackground: true,
         dialogUser: false,
         activeUser: null,
         pageCount: 0,
         usersPage: 1,
         users: [],
         selected_users: [],
         departments: [],
         pageSize: 6,
         loading: false,
         searchString: "",
         headers: [
            { text: "Tên", align: "start", sortable: true, value: "fullname" },
            // { text: "Mã NV", align: "start", sortable: true, value: "user_code", width: 100, },
            { text: "Email", align: "start", sortable: false, value: "email" },
            { text: "Quyền truy cập", align: "start", sortable: true, value: "roleStr" },
            // { text: "SĐT", align: "start", sortable: false, value: "mobile" },
            // { text: 'Khóa', align: 'start', sortable: true, value: 'lock', width: 80},
         ],
         roles: [
            { label: 'Quản trị', value:'admin' },
            { label: 'Vận hành', value:'operator' },
            { label: 'Khách', value:'guest' },
         ]
      }
   },
   methods: {
      getRoleStr (role) {
         let matchRole = this.roles.find(r => r.value ==role)
         if (matchRole) return matchRole.label
         return ""
      },
      showCreateUser  () {
         this.activeUser = {
            fullname: '',
            username: '',
            mobile: '',
            email: '',
         }
         this.dialogUser = true
      },
      showEditUser (user) {
         this.activeUser = JSON.parse(JSON.stringify(user))
         this.dialogUser = true
      },
      async fetchUsers () {
         if (!this.token) return
         this.users = []
         try {
            let res = await this.axios.get(this.$root.apiUser + `/user-manager/filter/user?customer_id=${this.$root.apiUserCustomerId}&preload_fields=role`, {
                                          headers: { "x-token": this.token },
                                       })
            if (res.data.status == "OK") {
               this.users = res.data.content
               this.users.forEach(u => {
                  u.roleStr = ''
                  if (u.roles.length>0) {
                     u.roleStr = u.roles.map(r => r.name).join(', ')
                  }
               })
            }
                                    
         } catch (err) { 
            if ( err.response) {
               console.log("Lỗi! " + err.response.data.message)
               return
            }
            console.log("Lỗi! " + err.message)
         }
      },
      onUserRowClick (user) {
         this.showEditUser(user)
      },
      async callDeleteUsersAPI () {
         if (this.selected_users.length<=0) return
         if(!confirm(`Xác nhận xóa '${this.selected_users.map(u => u.fullname).join(', ')}'?`)) return
         let userIDs = this.selected_users.map(u => u._id).join(';')
         try {
            let res = await this.axios.put(
               this.$root.apiUser + `/user-manager/delete/user/${userIDs}`,
               {},
               {
                  headers: { "x-token": this.token },
               })
            // console.log(res.data)
            if (res.data.status == 'OK') {
               this.fetchUsers()
               alert('Xóa thành công!')
            } else {
               alert(res.data.message)
               // console.log(res.data)
            }
         } catch (err) {
            if ( err.response) {
               console.log(err.response)
               if (err.response.data.status == "ERR_TOKEN") {
                  alert('Lỗi! Phiên đăng nhập hết hạn, vui lòng đăng nhập lại để xác nhận!')
                  return
               }
               alert("Lỗi! " + err.response.data.message)
               return
            }
            alert("Lỗi! " + err.message)
         }
      },
      async assignRole (userId, role_code) {
         try {
            await this.axios.put(
               this.$root.apiUser + `/user-manager/update/addRole/${userId}/${role_code}`,
               {},
               { headers: { "x-token": this.token } })
            await this.fetchUsers()
         } catch (err) { console.log(err) }
      },
      async removeRole (user) {
         if(!user) return
         if(!user.roles || user.roles.length<=0) return
         let code = user.roles[0].code
         try {
            await this.axios.put(
               this.$root.apiUser + `/user-manager/update/removeRole/${user._id}/${code}`,
               {},
               { headers: { "x-token": this.token } })
            await this.fetchUsers()
         } catch (err) { console.log(err) }
      }
   },
   async created () {
      await this.fetchUsers()
   }
}
</script>

<style>
   .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
   .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
   .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
      font-size: 18px !important;
   }

   .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, 
   .v-data-table > .v-data-table__wrapper > table > thead > tr > td, 
   .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
      font-size: 18px !important;
   }
</style>